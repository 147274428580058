/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum Target {
  EDUCATIONAL = 'EDUCATIONAL',
  INDIVIDUAL_AND_EDUCATIONAL = 'INDIVIDUAL_AND_EDUCATIONAL',
  INDIVIDUAL = 'INDIVIDUAL',
}
