/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum DMSApplicationstatus {
  ACCEPTE = 'accepte',
  SANS_SUITE = 'sans_suite',
  EN_CONSTRUCTION = 'en_construction',
  REFUSE = 'refuse',
  EN_INSTRUCTION = 'en_instruction',
}
