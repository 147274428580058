// overwrites request.ts to handle redirection to maintenance

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { URL_FOR_MAINTENANCE } from 'commons/utils/config'
import { ApiError } from './ApiError'
import type { ApiRequestOptions } from './ApiRequestOptions'
import type { ApiResult } from './ApiResult'
import type { OnCancel } from './CancelablePromise'
import { CancelablePromise } from './CancelablePromise'
import type { OpenAPIConfig } from './OpenAPI'

const isDefined = <T>(
  value: T | null | undefined
): value is Exclude<T, null | undefined> => {
  return value !== undefined && value !== null
}

const isString = (value: any): value is string => {
  return typeof value === 'string'
}

const isStringWithValue = (value: any): value is string => {
  return isString(value) && value !== ''
}

const isBlob = (value: any): value is Blob => {
  return (
    typeof value === 'object' &&
    typeof value.type === 'string' &&
    typeof value.stream === 'function' &&
    typeof value.arrayBuffer === 'function' &&
    typeof value.constructor === 'function' &&
    typeof value.constructor.name === 'string' &&
    /^(Blob|File)$/.test(value.constructor.name) &&
    /^(Blob|File)$/.test(value[Symbol.toStringTag])
  )
}

const isFormData = (value: any): value is FormData => {
  return value instanceof FormData
}

const base64 = (str: string): string => {
  try {
    return btoa(str)
  } catch (err) {
    return Buffer.from(str).toString('base64')
  }
}

const getQueryString = (params: Record<string, any>): string => {
  const qs: string[] = []

  const append = (key: string, value: any) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
  }

  const process = (key: string, value: any) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          process(key, v)
        })
      } else if (typeof value === 'object') {
        Object.entries(value).forEach(([k, v]) => {
          process(`${key}[${k}]`, v)
        })
      } else {
        append(key, value)
      }
    }
  }

  Object.entries(params).forEach(([key, value]) => {
    process(key, value)
  })

  if (qs.length > 0) {
    return `?${qs.join('&')}`
  }

  return ''
}

const getUrl = (config: OpenAPIConfig, options: ApiRequestOptions): string => {
  const encoder = config.ENCODE_PATH || encodeURI

  const path = options.url
    .replace('{api-version}', config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      if (options.path?.hasOwnProperty(group)) {
        return encoder(String(options.path[group]))
      }
      return substring
    })

  const url = `${config.BASE}${path}`
  if (options.query) {
    return `${url}${getQueryString(options.query)}`
  }
  return url
}

const getFormData = (options: ApiRequestOptions): FormData | undefined => {
  if (options.formData) {
    const formData = new FormData()

    const process = (key: string, value: any) => {
      if (isString(value) || isBlob(value)) {
        formData.append(key, value)
      } else {
        formData.append(key, JSON.stringify(value))
      }
    }

    Object.entries(options.formData)
      .filter(([_, value]) => isDefined(value))
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => process(key, v))
        } else {
          process(key, value)
        }
      })

    return formData
  }
  return undefined
}

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>

const resolve = async <T>(
  options: ApiRequestOptions,
  resolver?: T | Resolver<T>
): Promise<T | undefined> => {
  if (typeof resolver === 'function') {
    return (resolver as Resolver<T>)(options)
  }
  return resolver
}

const getHeaders = async (
  config: OpenAPIConfig,
  options: ApiRequestOptions
): Promise<Headers> => {
  const token = await resolve(options, config.TOKEN)
  const username = await resolve(options, config.USERNAME)
  const password = await resolve(options, config.PASSWORD)
  const additionalHeaders = await resolve(options, config.HEADERS)

  const headers = Object.entries({
    Accept: 'application/json',
    ...additionalHeaders,
    ...options.headers,
  })
    .filter(([_, value]) => isDefined(value))
    .reduce(
      (headers, [key, value]) => ({
        ...headers,
        [key]: String(value),
      }),
      {} as Record<string, string>
    )

  if (isStringWithValue(token)) {
    headers['Authorization'] = `Bearer ${token}`
  }

  if (isStringWithValue(username) && isStringWithValue(password)) {
    const credentials = base64(`${username}:${password}`)
    headers['Authorization'] = `Basic ${credentials}`
  }

  if (options.body) {
    if (options.mediaType) {
      headers['Content-Type'] = options.mediaType
    } else if (isBlob(options.body)) {
      headers['Content-Type'] = options.body.type || 'application/octet-stream'
    } else if (isString(options.body)) {
      headers['Content-Type'] = 'text/plain'
    } else if (!isFormData(options.body)) {
      headers['Content-Type'] = 'application/json'
    }
  }

  return new Headers(headers)
}

const getRequestBody = (options: ApiRequestOptions): any => {
  if (options.body) {
    if (options.mediaType?.includes('/json')) {
      return JSON.stringify(options.body)
    } else if (
      isString(options.body) ||
      isBlob(options.body) ||
      isFormData(options.body)
    ) {
      return options.body
    } else {
      return JSON.stringify(options.body)
    }
  }
  return undefined
}

export const sendRequest = (
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  url: string,
  body: any,
  formData: FormData | undefined,
  headers: Headers,
  onCancel: OnCancel
): Promise<Response> => {
  const controller = new AbortController()

  const request: RequestInit = {
    headers,
    body: body ?? formData,
    method: options.method,
    signal: controller.signal,
  }

  if (config.WITH_CREDENTIALS) {
    request.credentials = config.CREDENTIALS
  }

  onCancel(() => controller.abort())

  // we manually remove the await here
  // So that the parent can correctly catch the error
  return fetch(url, request)
}

const getResponseHeader = (
  response: Response,
  responseHeader?: string
): string | undefined => {
  if (responseHeader) {
    const content = response.headers.get(responseHeader)
    if (isString(content)) {
      return content
    }
  }
  return undefined
}

const getResponseBody = async (response: Response): Promise<any> => {
  if (response.status !== 204) {
    try {
      const contentType = response.headers.get('Content-Type')

      if (contentType) {
        const lowercaseContentType = contentType.toLowerCase()
        const isJSON = lowercaseContentType.startsWith('application/json')

        // Get around this issue
        // https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1739
        const isDownloadable = [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
          'text/csv',
          'application/pdf',
        ].some((downloadableMimeType) =>
          lowercaseContentType.includes(downloadableMimeType)
        )

        if (isJSON) {
          return await response.json()
        } else if (isDownloadable) {
          return await response.blob()
        } else {
          return await response.text()
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  return undefined
}

const catchErrorCodes = (
  options: ApiRequestOptions,
  result: ApiResult
): void => {
  const errors: Record<number, string> = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    ...options.errors,
  }

  const error = errors[result.status]

  // BEGIN CUSTOM SCRIPT
  if (result.status === 503) {
    window.location.assign(URL_FOR_MAINTENANCE)
    return
  }
  // END CUSTOM SCRIPT

  if (error) {
    throw new ApiError(options, result, error)
  }

  if (!result.ok) {
    throw new ApiError(options, result, 'Generic Error')
  }
}

/**
 * Request method
 * @param config The OpenAPI configuration object
 * @param options The request options from the service
 * @returns CancelablePromise<T>
 * @throws ApiError
 */
export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions
): CancelablePromise<T> => {
  return new CancelablePromise(async (resolve, reject, onCancel) => {
    try {
      const url = getUrl(config, options)
      const formData = getFormData(options)
      const body = getRequestBody(options)
      const headers = await getHeaders(config, options)

      if (!onCancel.isCancelled) {
        const response = await sendRequest(
          config,
          options,
          url,
          body,
          formData,
          headers,
          onCancel
        )
        const responseBody = await getResponseBody(response)
        const responseHeader = getResponseHeader(
          response,
          options.responseHeader
        )

        const result: ApiResult = {
          url,
          ok: response.ok,
          status: response.status,
          statusText: response.statusText,
          body: responseHeader ?? responseBody,
        }

        catchErrorCodes(options, result)
        resolve(result.body)
      }
    } catch (error) {
      if (error instanceof ApiError && error.status === 401) {
        if (error.url.includes('/adage-iframe')) {
          window.location.href = '/adage-iframe/erreur'
          return
        }
        // A call to users/current is made on all routes
        // including public routes
        // when user is not connected we always recieve a 401 error
        // so in that case we don't redirect to the login page (since the route is public)
        // when navigating in private routes, others calls will throw a 401
        // and redirect the user to the login page except if the call is made to users/signin
        if (
          !error.url.includes('/users/current') &&
          !error.url.includes('/users/signin')
        ) {
          window.location.href = '/connexion'
          return
        }
      }
      reject(error)
    }
  })
}
